let regionSelect = $('.region-select2');
let hafChoiceUrl = regionSelect.data('region-harbour-choices-url');

if (hafChoiceUrl)
{
    function addHarbours()
    {
        let optgroup = $('<optgroup>').attr('label', regionSelect.data('opt-group-label'));
        $.ajax({
            url: hafChoiceUrl,
            success: function (data) {
                data.forEach(function (xHaf)
                {
                    if (regionSelect.find("option[value='" + xHaf.xRegionId + "']").length) {
                        optgroup.append($('<option />')
                            .val(xHaf.xRegionId)
                            .text(xHaf.description)
                            .attr('data-haf-id', xHaf.id));
                    }
                });
            }
        });
        regionSelect.append(optgroup);
    }
    regionSelect.on('select2:select', function (e) {
        $('#yacht_search_xHafIdHidden').val($(e.params.data.element).data('haf-id'));
    });
    addHarbours();
}

regionSelect.select2({
    dropdownAutoWidth : true,
    allowClear: regionSelect.data('allow-clear'),
    matcher: matchStart,
    templateResult: fixPreferredChoices,
    templateSelection: removeLvl,
});

function isPreferredChoice(data) {
    return (data.element !== undefined && $(data.element).hasClass('preferred'));
}

function fixPreferredChoices(data) {
    if (data.text !== undefined && data.children === undefined && isPreferredChoice(data)) {
        return removeLvl(data);
    } else {
        return data.text;
    }
}

function removeLvl(data) {
    return data.text.replaceAll(/-\s/g, "");
}

function matchStart(params, data) {
    if ($.trim(params.term) === '') {
        return data;
    }

    if (typeof data.text === 'undefined') {
        return null;
    }

    // Root Elemente
    let result = matchElement(params, data);
    if (result) {
        return result;
    }

    // Kinder
    if (data.children !== undefined) {
        // Kinder und Root Element
        let filteredChildren = [];
        $.each(data.children, function (idx, child) {
            let result = matchElement(params, child);

            if (result) {
                filteredChildren.push(child);
            }
        });
        if (filteredChildren.length) {
            var modifiedData = $.extend({}, data, true);
            modifiedData.children = filteredChildren;

            return modifiedData;
        }
    }

    return null;
}

function matchElement(params, data)
{
    if (!isPreferredChoice(data)) {
        return data.text.search(RegExp(params.term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), "i")) > -1 ? data : null;
    } else {
        return null;
    }
}
